/* HIDE RADIO */
[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type='radio'] + img {
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 8px 8px #00000026;
  opacity: 0.4;
}

/* CHECKED STYLES */
[type='radio']:checked + img {
  outline: 2px solid rgb(43, 135, 243);
  opacity: 100%;
}
