.error:focus {
  box-shadow: 0 0 0 0.25rem rgb(255, 0, 0, 0.25) !important;
}

.tiny {
  font-size: 12px;
}

.border-focus:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
